import React from "react";
import styled from "styled-components";
import HerstellerAddress from "./Hersteller/HerstellerAddress";
import HerstellerMapGrid from "./Layout/Grids/HerstellerMapGrid";
import Wrapper from "./Layout/Wrapper";
import MapBox from "./MapBox";

interface HerstellerProps {
  inhalt: {
    latitude: number;
    longitude: number;
    title: string;
    uri: string;
  };
  mapCenter: {
    longitude: number;
    latitude: number;
    zoom: number;
  };
  lokalisierung: {
    anfrage: string;
    wegbeschreibung: string;
    website: string;
    herstellerEmailKontaktSubjekt: string;
  };
  siteId: number;
}

const HerstellerItem = styled.article`
  /* REMOVE WITH MAP */
  > div {
    width: 100%;
    > img {
      width: 100%;
      object-fit: contain;
    }
  }
`;

const Hersteller: React.FC<HerstellerProps> = ({
  inhalt,
  mapCenter,
  lokalisierung,
  siteId,
}) => (
  <HerstellerItem>
    <Wrapper>
      <HerstellerMapGrid>
        <HerstellerAddress
          lokalisierung={lokalisierung}
          inhalt={inhalt}
          siteId={siteId}
        />
        <MapBox
          data={[
            {
              index: 1,
              latitude: inhalt.latitude,
              longitude: inhalt.longitude,
              title: inhalt.title,
              uri: inhalt.uri,
            },
          ]}
          mapCenter={mapCenter}
        />
      </HerstellerMapGrid>
    </Wrapper>
  </HerstellerItem>
);

export default Hersteller;
