import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 2fr;
    column-gap: 30px;
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    grid-template-columns: 1fr 2fr;
    column-gap: 65px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    column-gap: 120px;
  }
`;

const HerstellerMapGrid: React.FC = ({ children }) => (
  <GridDiv>{children}</GridDiv>
);

export default HerstellerMapGrid;
