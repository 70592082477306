import React from "react";
import { graphql } from "gatsby";
import { Switzerland, Germany, Austria, USA, Italy } from "../data/data";
import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import Hersteller from "../components/Hersteller";

interface HerstellerProps {
  data: {
    cms: {
      inhalt: {
        siteId: number;
        title: string;
        id: string;
        greenLead: string;
        logo: [{ url: string }];
        email: string;
        mapsLink: string;
        subtitle: string;
        strasseHausnummer: string;
        postleitzahl: string;
        ort: string;
        land: string;
        telefon: string;
        websiteUrl: string;
        latitude: number;
        longitude: number;
      };
      lokalisierung: {
        website: string;
        anfrage: string;
        wegbeschreibung: string;
        herstellerEmailKontaktSubjekt: string;
      };
    };
  };
}

export const HerstellerQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_herstellerItem_Entry {
          id
          greenLead
          metaBeschreibung
          logo {
            url
          }
          email
          mapsLink
          strasseHausnummer
          postleitzahl
          ort
          land
          telefon
          websiteUrl
          latitude
          longitude
        }
      }
      lokalisierung: entry(type: "metaDatenLokalisierung") {
        ... on CMS_main_metaDatenLokalisierung_Entry {
          website
          anfrage
          wegbeschreibung
          herstellerEmailKontaktSubjekt
        }
      }
    }
  }
`;

const countries = [Switzerland, USA, Austria, Germany, Italy];

const HerstellerTemplate: React.FC<HerstellerProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.inhalt.siteId}
      title={data.cms.inhalt.title}
      //links={data.cms.inhalt.localized}
      beschreibung={data.cms.inhalt.metaBeschreibung}
    >
      <HeaderTitle inhalt={data.cms.inhalt.title} />
      <GreenLead inhalt={data.cms.inhalt.greenLead} />
      <Hersteller
        mapCenter={countries.find((obj) => obj.name === data.cms.inhalt.land)}
        siteId={data.cms.inhalt.siteId}
        inhalt={data.cms.inhalt}
        lokalisierung={data.cms.lokalisierung}
      />
    </DefaultLayout>
  );
};

export default HerstellerTemplate;
