import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

interface KontaktButtonProps {
  link: string;
  text: string;
  type: string;
  subject?: string;
}

const ButtonA = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.green};
  color: white;
  padding: 7px 11px 8px;
  font: 18px / 26px "Oswald Regular";
  width: calc(max-content + 40px);
  margin-bottom: 15px;
  max-width: 300px;
  &:first-of-type {
    margin-top: 25px;
  }

  .mail {
    width: 26px;
    height: 26px;
    margin: auto 0;
  }

  .map {
    width: 25px;
    height: 25px;
    margin: auto 0;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .mail {
      width: 32px;
      height: 32px;
    }

    .map {
      width: 31px;
      height: 31px;
    }

    &:first-of-type {
      margin-top: 32px;
    }
    max-width: 400px;
    padding: 8px 18px 9px;
    font: 21px / 31px "Oswald Regular";
    margin-bottom: 15px;
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    &:first-of-type {
      margin-top: 42px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .mail {
      width: 35px;
      height: 35px;
    }

    .map {
      width: 34px;
      height: 34px;
    }
    padding: 8px 20px 9px;
    font: 24px / 36px "Oswald Regular";
  }
`;
//position arrow absolutely
const KontaktButton: React.FC<KontaktButtonProps> = ({
  link,
  text,
  type,
  subject,
}) => (
  <ButtonA href={link + "?subject=" + subject} target="_blank">
    {text}{" "}
    {type === "email" ? (
      <img
        className="mail"
        src="/assets/gestaltungselemente/email.svg"
        alt={text + " email icon"}
      />
    ) : (
      <img
        className="map"
        src="/assets/gestaltungselemente/map-pin-new.svg"
        alt="Maps icon"
      />
    )}
  </ButtonA>
);

export default KontaktButton;
