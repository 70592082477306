import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import KontaktButton from "../Buttons/KontaktButton";
import Imgix from "react-imgix";
import { imgix } from "../../data/data";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";

interface HerstellerAddressProps {
  inhalt: {
    title: string;
    logo: [{ url: string }];
    strasseHausnummer: string;
    postleitzahl: string;
    ort: string;
    land: string;
    telefon: string;
    websiteUrl: string;
    email: string;
    mapsLink: string;
  };
  lokalisierung: {
    anfrage: string;
    wegbeschreibung: string;
    website: string;
    herstellerEmailKontaktSubjekt: string;
  };
}

const AddressDiv = styled.div`
  .imageDiv,
  address {
    margin-bottom: 25px;
    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .imageDiv {
      margin-bottom: 27px;
    }
    address {
      margin-bottom: 32px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    .imageDiv {
      margin-bottom: 30px;
    }
    address {
      margin-bottom: 43px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .imageDiv {
      margin-bottom: 33px;
    }
    address {
      margin-bottom: 42px;
    }
  }
`;

const HerstellerAddress: React.FC<HerstellerAddressProps> = ({
  inhalt,
  lokalisierung,
  siteId,
}) => {
  const country = (land) => {
    if (land === "USA") {
      return "USA";
    } else if (land === "Schweiz") {
      return siteId === 1
        ? "Schweiz"
        : siteId === 2
        ? "Switzerland"
        : siteId === 3
        ? "Suisse"
        : siteId === 4
        ? "Svizzera"
        : "";
    } else if (land === "Deutschland") {
      return siteId === 1
        ? "Deutschland"
        : siteId === 2
        ? "Germany"
        : siteId === 3
        ? "Allemagne"
        : siteId === 4
        ? "Germania"
        : "";
    } else if (land === "Österreich") {
      return siteId === 1
        ? "Österreich"
        : siteId === 2
        ? "Austria"
        : siteId === 3
        ? "Autriche"
        : siteId === 4
        ? "Austria"
        : "";
    } else if (land === "Italien") {
      return siteId === 1
        ? "Italien"
        : siteId === 2
        ? "Italy"
        : siteId === 3
        ? "Italie"
        : siteId === 4
        ? "Italia"
        : "";
    }
  };
  return (
    <AddressDiv>
      {inhalt.logo.length > 0 ? (
        <div className="imageDiv">
          <a href={inhalt.websiteUrl} target="_blank">
            <Imgix
              src={inhalt.logo[0].url}
              imgixProps={imgix.Img}
              htmlAttributes={{ alt: inhalt.title + " logo" }}
            />
          </a>
        </div>
      ) : (
        ""
      )}
      <address>
        <p>
          <b
            dangerouslySetInnerHTML={{
              __html: superScriptRHelperHTML(inhalt.title),
            }}
          ></b>
          <br />
          {inhalt.strasseHausnummer}
          <br />
          {inhalt.postleitzahl} {inhalt.ort}, {country(inhalt.land)}
          <br />
          <a href={"tel:" + inhalt.telefon}>{inhalt.telefon}</a>
          {inhalt.websiteUrl ? (
            <>
              <br />
              <a href={inhalt.websiteUrl} target="_blank">
                {lokalisierung.website}
              </a>
            </>
          ) : (
            ""
          )}
        </p>
        {inhalt.email ? (
          <KontaktButton
            text={lokalisierung.anfrage}
            type="email"
            link={"mailto:" + inhalt.email}
            subject={lokalisierung.herstellerEmailKontaktSubjekt}
          />
        ) : (
          ""
        )}
        <KontaktButton
          text={lokalisierung.wegbeschreibung}
          type="map"
          link={inhalt.mapsLink}
        />
      </address>
    </AddressDiv>
  );
};

export default HerstellerAddress;
